import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { MantineProvider } from "@mantine/core";
import configureAppStore from './store/store';
import { Notifications } from '@mantine/notifications';
import App from './App';
import './index.css';
import './responsive.css';
import './auto.css';
import './styles/fonts/fonts.css'
import 'react-image-upload/dist/index.css'

const container = document.getElementById("root");
const root = createRoot(container);
const store = configureAppStore()

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

root.render(
  <Provider store={store}>
    <MantineProvider theme={{
      loader: 'dots',
      fontFamily: 'Mont,Regular',
      fontFamilyMonospace: 'Mont,Regular',
      headings: { fontFamily: 'Mont,SemiBold' },
      colors: {
        'font-color': ['#484848', '#302F46', '#393939', '#5A5A5A', "#0094D1"],
        'success': ['#0AD27B'],
        'danger': ['#E63C3C'],
        'orange': ['#E6A23C'],
        'grey': ['#484848', '#393939', '#302F46', '#5A5A5A'],
        'buttonColor': ['#1E55A5']
      },

      components: {
        Button: {
          styles: () => ({
            root: {
              ":active": { transform: "none" },
              height: '1.875rem',
              minWidth: '7rem'
            },
          })
        },
      },

      fontSizes: {
        xs: '0.65rem',
        sm: '0.70rem',
        md: '0.77rem',
        lg: '0.80rem',
        xl: '1rem',
        xxl: '1.8rem',
        head: '1.3rem',
      },

    }}>
      <Notifications position="top-right" zIndex={2077} />
      <App />
    </MantineProvider>
  </Provider>
);
