import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "redux/api";

export const lumberPriceListService = createAsyncThunk("lumberPriceListService", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/lumberprice`, {
			params,
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const lumberPriceViewService = createAsyncThunk("lumberPriceViewService", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/lumberprice/${params?.lumberID}/${params?.seprateID}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const lumberPriceUpdateService = createAsyncThunk("lumberPriceUpdateService", async (params, thunkAPI) => {
	try {
		const response = await API.post(`/lumberprice/${params?.id}`, params);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const lumberPriceAddService = createAsyncThunk("lumberPriceAddService", async (data = {}, thunkAPI) => {
	try {
		const response = await API.post(`/lumberprice`, data);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const lumberPriceDeleteService = createAsyncThunk("lumberPriceDeleteService", async (deleteParams, thunkAPI) => {
	try {
		const response = await API.delete(`/lumberprice/${deleteParams?.id}/${deleteParams?.seperate_lumber_id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
