import { createSlice } from "@reduxjs/toolkit";
import { woodCreateService, woodDeleteService, woodListService, woodUpdateService, woodViewService } from "redux/service/woodService";

const woodCases = [
  {
    api: woodListService,
    name: "woodListService",
  },
  {
    api: woodViewService,
    name: "woodViewService",
  },
  {
    api: woodCreateService,
    name: "woodCreateService",
  },
  {
    api: woodUpdateService,
    name: "woodUpdateService",
  },
  {
    api: woodDeleteService,
    name: "woodDeleteService",
  }
];

let initialState = {};
woodCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const woodSlice = createSlice({
  name: "wood",
  initialState,
  extraReducers: (builder) => {
    woodCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default woodSlice.reducer;
