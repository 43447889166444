import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// production sheet preview
export const productionSheetListService = createAsyncThunk("productionSheetList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/production-sheet?per_page=${bodyParams.per_page}&page=${bodyParams.page}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// move order service
export const moveOrderService = createAsyncThunk("moveOrder", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(
			`/sale-pack/action/${bodyParams.id}?action=${bodyParams.action}`,
			bodyParams?.formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// work order service

export const workOrderService = createAsyncThunk("workOrderService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/work-order?ht_status=${bodyParams?.status}&per_page=${bodyParams.per_page}&search=${bodyParams.search
			}&page=${bodyParams.page}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// work order view service

export const workOrderViewService = createAsyncThunk("workOrderViewService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/work-order?work_id=${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// order service

export const orderListService = createAsyncThunk("orderList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/sale-pack?action=${bodyParams?.type}&per_page=${bodyParams.per_page}&search=${bodyParams.search
			}&page=${bodyParams.page}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const orderViewService = createAsyncThunk("orderView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/sale-pack/${bodyParams.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const orderCreateService = createAsyncThunk("orderCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/sale-pack`, bodyParams);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const orderUpdateService = createAsyncThunk("orderUpdate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/sale-pack/${bodyParams?.id}`, bodyParams);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const orderDeleteService = createAsyncThunk("orderDelete", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/sale-pack/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
