import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// allmaterial service

export const allMaterialListService = createAsyncThunk("allMaterialListService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/material?type=${bodyParams.type}&per_page=${bodyParams.per_page}&search=${bodyParams.search}&page=${
				bodyParams.page
			}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
				bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// pallet service

export const palletListService = createAsyncThunk("palletList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/pallet?per_page=${bodyParams.per_page}&search=${bodyParams.search}&page=${
				bodyParams.page
			}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
				bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const palletViewService = createAsyncThunk("palletView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/pallet/${bodyParams.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const palletCreateService = createAsyncThunk("palletCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/pallet`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const palletUpdateService = createAsyncThunk("palletUpdate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/pallet/${bodyParams?.id}`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const palletDeleteService = createAsyncThunk("palletDelete", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/pallet/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
