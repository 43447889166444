import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "redux/api";

export const accountListService = createAsyncThunk("accountListService", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/account`, {
			params,
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const accountViewService = createAsyncThunk("accountView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/account/${bodyParams.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const accountEnableLogin = createAsyncThunk("accountEnableLogin", async (bodyParams, thunkAPI) => {
	const { data, id } = bodyParams;
	try {
		const response = await API.patch(`/account/${id}`, data);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const accountCreateService = createAsyncThunk("accountCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/account`, bodyParams);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const accountUpdateService = createAsyncThunk("accountUpdate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/account/${bodyParams?.id}`, bodyParams?.data);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const companyProfileListService = createAsyncThunk("companyProfileListService", async (data, thunkAPI) => {
	try {
		const response = await API.get(`/company`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const companyProfileUpdateService = createAsyncThunk("companyProfileUpdateService", async (data, thunkAPI) => {
	try {
		const response = await API.post("/company", data, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const customerProfileGetService = createAsyncThunk("customerProfileGetService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/company/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
