import { createSlice } from "@reduxjs/toolkit";
import { htDocumentDownload, fileHandlerDownload, fileHandlerEmail } from "redux/service/EmailDownloadService";

const emailDownloadCases = [
  {
    api: htDocumentDownload,
    name: "htDocumentDownload",
  },
  {
    api: fileHandlerDownload,
    name: "fileHandlerDownload",
  },
  {
    api: fileHandlerEmail,
    name: "fileHandlerEmail",
  }
];

let initialState = {};
emailDownloadCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const emailDownloadSlice = createSlice({
  name: "customer",
  initialState,
  extraReducers: (builder) => {
    emailDownloadCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default emailDownloadSlice.reducer;
