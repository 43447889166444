import { createSlice } from "@reduxjs/toolkit";

const { lumberCalculatorAddService, lumberCalculatorListService, lumberCalculatorViewService, lumberCalculatorUpdateService, lumberCalculatorDeleteService } = require("redux/service/lumberCalculatorService");

const lumberCalculatorCases = [
    {
        api: lumberCalculatorDeleteService,
        name: "lumberCalculatorDeleteService"
    },
    {
        api: lumberCalculatorUpdateService,
        name: "lumberCalculatorUpdateService"
    },
    {
        api: lumberCalculatorViewService,
        name: "lumberCalculatorViewService"
    },
    {
		api: lumberCalculatorListService,
		name: "lumberCalculatorListService",
	},
    {
        api: lumberCalculatorAddService,
        name: "lumberCalculatorAddService"
    },
    
]

let initialState = {};

lumberCalculatorCases?.forEach((api) => {
    initialState[api.name] = {
        loading: false,
        data: null,
        error: null
    };
})

export const lumberCalculatorSlice = createSlice({
    name: "lumberCalculator",
    initialState,
    extraReducers: ( builder ) => {
        lumberCalculatorCases.forEach( ( cases ) => {
            builder
            .addCase( cases.api.fulfilled, ( state, { payload } ) => {
                state[cases.name].loading = false;
                state[cases.name].data = payload?.data ?? null;
                state[cases.name].error = null;
            } )
            .addCase( cases.api.pending, ( state ) => {
                state[cases.name].loading = true;
                state[cases.name].error = null;
                state[cases.name].data = null;
            } )
            .addCase( cases.api.rejected, ( state, { payload }) => {
                state[cases.name].loading = false;
                state[cases.name].error = payload;
                state[cases.name].data = null;
            } );
        } )
    }
})