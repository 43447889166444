import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// customer service

export const customerListService = createAsyncThunk("customerList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/customer?per_page=${bodyParams.per_page}&search=${bodyParams.search}&page=${
				bodyParams.page
			}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
				bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const customerViewService = createAsyncThunk("customerView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/customer/${bodyParams.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const customerCreateService = createAsyncThunk("customerCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/customer`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const customerUpdateService = createAsyncThunk("customerUpdate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/customer/${bodyParams?.id}`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const customerDeleteService = createAsyncThunk("customerDelete", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/customer/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// customer products

export const customerProductService = createAsyncThunk("customerProductService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/customer/pallets/${bodyParams?.id}?per_page=${bodyParams.per_page}&page=${
				bodyParams.page
			}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
				bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// customer work order

export const customerWorkOrderService = createAsyncThunk("customerWorkOrderService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/work-order/${bodyParams?.id}?per_page=${bodyParams.per_page}&page=${bodyParams.page}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
				bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

// customer packing slip

export const customerPackingSlipService = createAsyncThunk(
	"customerPackingSlipService",
	async (bodyParams, thunkAPI) => {
		try {
			const response = await API.get(
				`/customer/packing-slips/${bodyParams?.id}?per_page=${bodyParams.per_page}&page=${
					bodyParams.page
				}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&action=${
					bodyParams?.action
				}&sort=${bodyParams?.sort || "desc"}&sort_column=${bodyParams?.sort_column || ""}`
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

// customer deleted packing slip

export const customerDeletedPackingSlipService = createAsyncThunk(
	"customerDeletedPackingSlipService",
	async (bodyParams, thunkAPI) => {
		try {
			const response = await API.get(
				`customer/sale-orders/${bodyParams?.id}?action=removed&per_page=${bodyParams.per_page}&page=${
					bodyParams.page
				}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
					bodyParams?.sort || "desc"
				}&sort_column=${bodyParams?.sort_column || ""}`
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

// customer deleted product
export const customerDeletedProductService = createAsyncThunk(
	"customerDeletedProductService",
	async (bodyParams, thunkAPI) => {
		try {
			const response = await API.get(
				`customer/pallets/${bodyParams?.id}?deleted=true&action=removed&per_page=${
					bodyParams.per_page
				}&page=${bodyParams.page}&from_date=${bodyParams.from_date}&to_date=${
					bodyParams.to_date
				}&sort=${bodyParams?.sort || "desc"}&sort_column=${bodyParams?.sort_column || ""}`
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

// customer cancelled order
export const customerCancelledOrderService = createAsyncThunk(
	"customerCancelledOrderService",
	async (bodyParams, thunkAPI) => {
		try {
			const response = await API.get(
				`customer/sale-orders/${bodyParams?.id}?action=cancel&per_page=${bodyParams.per_page}&page=${
					bodyParams.page
				}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
					bodyParams?.sort || "desc"
				}&sort_column=${bodyParams?.sort_column || ""}`
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

// customer cancelled order
export const customerQuotationService = createAsyncThunk("customerQuotationService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`quotation?customer_id=${bodyParams?.id}&per_page=${bodyParams.per_page}&page=${
				bodyParams.page
			}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
				bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
