import { createSlice } from "@reduxjs/toolkit";
import { fastnerCreateService, fastnerDeleteService, fastnerListService, fastnerUpdateService, fastnerViewService } from "redux/service/fastnerService";

const fastersCases = [
  {
    api: fastnerCreateService,
    name: "fastnerCreateService",
  },
  {
    api: fastnerUpdateService,
    name: "fastnerUpdateService",
  },
  {
    api: fastnerListService,
    name: "fastnerListService",
  },
  {
    api: fastnerDeleteService,
    name: "fastnerDeleteService",
  },
  {
    api: fastnerViewService,
    name: "fastnerViewService",
  }
];

let initialState = {};
fastersCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const fastnersSlice = createSlice({
  name: "customer",
  initialState,
  extraReducers: (builder) => {
    fastersCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default fastnersSlice.reducer;
