import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "redux/api";

export const uploadHtDocument = createAsyncThunk("uploadHtDocument", async (data, thunkAPI) => {
	try {
		const response = await API.post(`/ht`, data, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const htDocumentList = createAsyncThunk("htDocumentList", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/ht`, {
			params,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
export const htDocumentDelete = createAsyncThunk("htDocumentDelete", async (id, thunkAPI) => {
	try {
		const response = await API.delete(`/ht/` + id);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
