import { createSlice } from "@reduxjs/toolkit";
import {
  customerCreateService, customerDeleteService, customerListService, customerUpdateService, customerViewService,
  customerProductService, customerWorkOrderService, customerPackingSlipService, customerDeletedPackingSlipService,
  customerDeletedProductService, customerCancelledOrderService, customerQuotationService
} from "redux/service/customerService";

const customerCases = [
  {
    api: customerCreateService,
    name: "customerCreateService",
  },
  {
    api: customerUpdateService,
    name: "customerUpdateService",
  },
  {
    api: customerListService,
    name: "customerListService",
  },
  {
    api: customerDeleteService,
    name: "customerDeleteService",
  },
  {
    api: customerViewService,
    name: "customerViewService",
  },
  {
    api: customerProductService,
    name: "customerProductService"
  },
  {
    api: customerWorkOrderService,
    name: "customerWorkOrderService"
  },
  {
    api: customerPackingSlipService,
    name: "customerPackingSlipService"
  },
  {
    api: customerDeletedPackingSlipService,
    name: "customerDeletedPackingSlipService"
  },
  {
    api: customerDeletedProductService,
    name: "customerDeletedProductService"
  },
  {
    api: customerCancelledOrderService,
    name: "customerCancelledOrderService"
  }, {
    api: customerQuotationService,
    name: "customerQuotationService"
  }
];

let initialState = {};
customerCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  extraReducers: (builder) => {
    customerCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default customerSlice.reducer;
