import { createSlice } from "@reduxjs/toolkit";
import {
  moveOrderService, orderCreateService, orderDeleteService, orderListService, orderUpdateService,
  orderViewService, productionSheetListService, workOrderService, workOrderViewService
} from "redux/service/orderService";

const orderCases = [
  {
    api: orderCreateService,
    name: "orderCreateService",
  },
  {
    api: orderUpdateService,
    name: "orderUpdateService",
  },
  {
    api: orderListService,
    name: "orderListService",
  },
  {
    api: orderDeleteService,
    name: "orderDeleteService",
  },
  {
    api: orderViewService,
    name: "orderViewService",
  },
  {
    api: moveOrderService,
    name: "moveOrderService",
  },
  {
    api: workOrderService,
    name: "workOrderService",
  },
  {
    api: workOrderViewService,
    name: "workOrderViewService"
  },
  {
    api: productionSheetListService,
    name: "productionSheetListService",
  },
];

let initialState = {};
orderCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const orderSlice = createSlice({
  name: "order",
  initialState,
  extraReducers: (builder) => {
    orderCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default orderSlice.reducer;
