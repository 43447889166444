import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "redux/api";

export const formDocList = createAsyncThunk("formDocList", async (params, thunkAPI) => {
	try {
		const response = await API.get(`/form`, {
			params,
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const formDocUpload = createAsyncThunk("formDocUpload", async (data, thunkAPI) => {
	try {
		const response = await API.post(`/form`, data, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const formDocumentDelete = createAsyncThunk("formDocumentDelete", async (id, thunkAPI) => {
	try {
		const response = await API.delete(`/form/` + id);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
