import { createSlice } from "@reduxjs/toolkit";
import {
	accountCreateService,
	accountEnableLogin,
	accountListService,
	accountUpdateService,
	accountViewService,
	companyProfileListService,
	companyProfileUpdateService,
} from "../service/settingService";

const settingCases = [
	{
		api: accountListService,
		name: "accountListService",
	},
	{
		api: accountCreateService,
		name: "accountCreateService",
	},
	{
		api: accountUpdateService,
		name: "accountUpdateService",
	},
	{
		api: accountViewService,
		name: "accountViewService",
	},
	{
		api: accountEnableLogin,
		name: "accountEnableLogin",
	},
	{
		api: companyProfileListService,
		name: "companyProfileListService",
	},
	{
		api: companyProfileUpdateService,
		name: "companyProfileUpdateService",
	},
];

let initialState = {};

settingCases.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const settingSlice = createSlice({
	name: "settings",
	initialState,
	extraReducers: (builder) => {
		settingCases.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
					state[cases.name].data = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
					state[cases.name].data = null;
				});
		});
	},
});

export default settingSlice.reducer;
