import { Box, Divider, Flex, Grid, Image, Modal, Tabs, Text, TextInput } from "@mantine/core";
import Styles from "components/style/style";
import { lumberFormula } from "helpers/global";
import { countAddIcon, countMinusIcon } from "helpers/images";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

const Calculator = ({ type }) => {
	const { watch, setValue } = useForm({
		defaultValues: {
			thick: 0,
			wide: 0,
			long: 0,
			price_1000: 0,
			fbm: 0,
			each: 0,
			pcs: 0,
			total: 0,
		},
	});

	const calculateEach = useCallback(() => {
		const value = lumberFormula(type, watch).calculateEach;
		setValue("each", value);
	}, [watch, setValue, type]);

	const calculatePCS = useCallback(() => {
		const value = lumberFormula(type, watch).calculatePCS;
		if (type >= 2) {
			setValue("fbm", value);
		} else {
			setValue("pcs", value);
		}
	}, [watch, setValue, type]);

	const calculateTotal = useCallback(() => {
		const value = lumberFormula(type, watch).calculateTotal;
		setValue("total", value);
	}, [watch, setValue, type]);

	const handleQuantiy = (name, funtionType, value) => {
		if (funtionType === "add") {
			setValue(name, watch(name) + 1);
			calculateEach(name);
			calculatePCS(name);
			calculateTotal(name);
		} else if (funtionType === "type") {
			setValue(name, value);
			calculateEach(name);
			calculatePCS(name);
			calculateTotal(name);
		} else {
			setValue(name, watch(name) - 1);
			calculateEach(name);
			calculatePCS(name);
			calculateTotal(name);
		}
	};
	return (
		<Box
			sx={{
				"& .mantine-Text-root": {
					fontSize: "16px",
					color: "#5A5A5A",
				},
				"& span": {
					fontSize: "16px",
					color: "#5A5A5A",
				},
			}}
		>
			{/* <Text
				sx={{
					fontSize: "19px !important",
				}}
			>
				Lumber Calculator
			</Text> */}
			<Grid gutter={20}>
				<Grid.Col
					span={4}
					sx={{
						marginBlock: "6px",
					}}
				>
					<Text>Thick</Text>
					<Flex align={"center"} gap={"xs"}>
						<Image className="cP" src={countMinusIcon} width={20} onClick={() => watch("thick") > 0 && handleQuantiy("thick", "min")} />
						{/* <span>{isNaN(watch("thick")) ? 0 : watch("thick")}</span> */}
						<TextInput
							sx={{
								width: "50px",
								border: "0.0625rem solid #ced4da",
								"& input": {
									fontSize: "16px",
									border: "none",
									outline: "none",
								},
							}}
							type="number"
							size="xs"
							value={isNaN(watch("thick")) ? 0 : watch("thick")}
							onChange={(e) => {
								handleQuantiy("thick", "type", Number(e.target.value));
							}}
						/>
						<Image className="cP" src={countAddIcon} width={20} onClick={() => handleQuantiy("thick", "add")} />
					</Flex>
				</Grid.Col>
				<Grid.Col
					span={4}
					sx={{
						marginBlock: "6px",
					}}
				>
					<Text>Wide</Text>
					<Flex align={"center"} gap={"xs"}>
						<Image className="cP" src={countMinusIcon} width={20} onClick={() => watch("wide") > 0 && handleQuantiy("wide", "min")} />
						{/* <span>{isNaN(watch("wide")) ? 0 : watch("wide")}</span> */}
						<TextInput
							sx={{
								width: "50px",
								border: "0.0625rem solid #ced4da",
								"& input": {
									fontSize: "16px",
									border: "none",
									outline: "none",
								},
							}}
							type="number"
							size="xs"
							value={isNaN(watch("wide")) ? 0 : watch("wide")}
							onChange={(e) => {
								handleQuantiy("wide", "type", Number(e.target.value));
							}}
						/>
						<Image className="cP" src={countAddIcon} width={20} onClick={() => handleQuantiy("wide", "add")} />
					</Flex>
				</Grid.Col>
				<Grid.Col
					span={4}
					sx={{
						marginBlock: "6px",
					}}
				>
					<Text>Long</Text>
					<Flex align={"center"} gap={"xs"}>
						<Image className="cP" src={countMinusIcon} width={20} onClick={() => watch("long") > 0 && handleQuantiy("long", "min")} />
						{/* <span>{isNaN(watch("long")) ? 0 : watch("long")}</span> */}
						<TextInput
							sx={{
								width: "50px",
								border: "0.0625rem solid #ced4da",
								"& input": {
									fontSize: "16px",
									border: "none",
									outline: "none",
								},
							}}
							size="xs"
							type="number"
							value={isNaN(watch("long")) ? 0 : watch("long")}
							onChange={(e) => {
								handleQuantiy("long", "type", Number(e.target.value));
								setValue("long", Number(e.target.value));
							}}
						/>
						<Image className="cP" src={countAddIcon} width={20} onClick={() => handleQuantiy("long", "add")} />
					</Flex>
				</Grid.Col>
			</Grid>
			<Grid>
				<Grid.Col
					span={4}
					sx={{
						marginBlock: "6px",
					}}
				>
					<Text>Price/1000</Text>
					<Flex align={"center"} gap={"xs"}>
						<Image className="cP" src={countMinusIcon} width={20} onClick={() => watch("price_1000") > 0 && handleQuantiy("price_1000", "min")} />
						{/* <span>{isNaN(watch("price_1000")) ? 0 : watch("price_1000")}</span> */}
						<TextInput
							sx={{
								width: "50px",
								border: "0.0625rem solid #ced4da",
								"& input": {
									fontSize: "16px",
									border: "none",
									outline: "none",
								},
							}}
							size="xs"
							type="number"
							value={isNaN(watch("price_1000")) ? 0 : watch("price_1000")}
							onChange={(e) => {
								handleQuantiy("price_1000", "type", Number(e.target.value));
							}}
						/>
						<Image className="cP" src={countAddIcon} width={20} onClick={() => handleQuantiy("price_1000", "add")} />
					</Flex>
				</Grid.Col>
				{type >= 2 ? (
					<Grid.Col
						span={4}
						sx={{
							marginBlock: "6px",
						}}
					>
						<Text>PCS</Text>
						<Flex align={"center"} gap={"xs"}>
							<Image className="cP" src={countMinusIcon} width={20} onClick={() => watch("pcs") > 0 && handleQuantiy("pcs", "min")} />
							{/* <span>{isNaN(watch("pcs")) ? 0 : watch("pcs")}</span> */}
							<TextInput
								sx={{
									width: "50px",
									border: "0.0625rem solid #ced4da",
									"& input": {
										fontSize: "16px",
										border: "none",
										outline: "none",
									},
								}}
								type="number"
								size="xs"
								value={isNaN(watch("pcs")) ? 0 : watch("pcs")}
								onChange={(e) => {
									handleQuantiy("pcs", "type", Number(e.target.value));
								}}
							/>
							<Image className="cP" src={countAddIcon} width={20} onClick={() => handleQuantiy("pcs", "add")} />
						</Flex>
					</Grid.Col>
				) : (
					<Grid.Col
						span={4}
						sx={{
							marginBlock: "6px",
						}}
					>
						<Text>F.B.M</Text>
						<Flex align={"center"} gap={"xs"}>
							<Image className="cP" src={countMinusIcon} width={20} onClick={() => watch("fbm") > 0 && handleQuantiy("fbm", "min")} />
							{/* <span>{isNaN(watch("fbm")) ? 0 : watch("fbm")}</span> */}
							<TextInput
								sx={{
									width: "50px",
									border: "0.0625rem solid #ced4da",
									"& input": {
										fontSize: "16px",
										border: "none",
										outline: "none",
									},
								}}
								type="number"
								size="xs"
								value={isNaN(watch("fbm")) ? 0 : watch("fbm")}
								onChange={(e) => {
									handleQuantiy("fbm", "type", Number(e.target.value));
								}}
							/>
							<Image className="cP" src={countAddIcon} width={20} onClick={() => handleQuantiy("fbm", "add")} />
						</Flex>
					</Grid.Col>
				)}
			</Grid>
			<Grid
				sx={{
					marginBlock: "6px",
				}}
			>
				<Grid.Col
					span={4}
					sx={{
						marginBlock: "6px",
						// marginRight : "6px"
					}}
				>
					<Text>Each</Text>
					<Flex align={"center"} gap={"xs"}>
						<span>{isNaN(watch("each")) ? Number(0).toFixed(2) : Number(watch("each")).toFixed(2)}</span>
					</Flex>
				</Grid.Col>
				{type < 2 ? (
					<Grid.Col
						span={4}
						sx={{
							marginBlock: "6px",
						}}
					>
						<Text>PCS</Text>
						<Flex align={"center"} gap={"xs"}>
							<span>{isNaN(watch("pcs")) ? Number(0).toFixed(2) : Number(watch("pcs")).toFixed(2)}</span>
						</Flex>
					</Grid.Col>
				) : (
					<Grid.Col
						span={4}
						sx={{
							marginBlock: "6px",
						}}
					>
						<Text>F.B.M</Text>
						<Flex align={"center"} gap={"xs"}>
							<span>{isNaN(watch("fbm")) ? Number(0).toFixed(2) : Number(watch("fbm")).toFixed(2)}</span>
						</Flex>
					</Grid.Col>
				)}

				<Grid.Col
					span={4}
					sx={{
						marginBlock: "6px",
					}}
				>
					<Text>Total</Text>
					<Flex align={"center"} gap={"xs"}>
						<span>{isNaN(watch("total")) ? Number(0).toFixed(2) : Number(watch("total")).toFixed(2)}</span>
					</Flex>
				</Grid.Col>
			</Grid>
		</Box>
	);
};

const LumberCalculatorPopup = ({ opened, onClose = () => { } }) => {
	const { classes } = Styles();
	return (
		<Modal
			opened={opened}
			centered
			size={"xl"}
			onClose={onClose}
			title={"Lumber Calculator"}
			sx={{
				"& .mantine-1lfy3d8": {
					width: "max-content",
				},
				"& .mantine-Modal-title": {
					fontSize: "19px",
					fontWeight: 500,
				},
			}}
		>
			<Tabs activateTabWithKeyboard={false} classNames={classes} variant="pills" orientation={"vertical"} defaultValue="1">
				<Tabs.List className={classes.tabsList}>
					<Tabs.Tab value="1">Feet F.B.M to PCS</Tabs.Tab>
					<Tabs.Tab value="2">Inch F.B.M to PCS</Tabs.Tab>
					<Tabs.Tab value="3">Feet PCS to F.B.M</Tabs.Tab>
					<Tabs.Tab value="4">Inch PCS to F.B.M</Tabs.Tab>
				</Tabs.List>
				<Divider orientation="vertical" />
				<Tabs.Panel value="1" px={20}>
					<Calculator type={0} />
				</Tabs.Panel>
				<Tabs.Panel value="2" px={20}>
					<Calculator type={1} />
				</Tabs.Panel>
				<Tabs.Panel value="3" px={20}>
					<Calculator type={2} />
				</Tabs.Panel>
				<Tabs.Panel value="4" px={20}>
					<Calculator type={3} />
				</Tabs.Panel>
			</Tabs>
		</Modal>
	);
};

export default LumberCalculatorPopup;
