import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomerAddress,
	essentialCreateService,
	essentialDeleteService,
	essentialService,
	essentialServiceByCompany,
	getDashboardListService,
} from "../service/essentialService";

const initialState = {
	essentialResponse: { data: [], isFetching: false, error: null },
	dashboardRes: { data: [], isFetching: false, error: null },
	essentialCompanyResponse: { data: [], isFetching: false, error: null },
	essentialCreateResponse: { data: [], isFetching: false, error: null },
	essentialDeleteResponse: { data: [], isFetching: false, error: null },
	addCustomerAddress: { data: [], isFetching: false, error: null },
};

export const essentialSlice = createSlice({
	name: "essentialSlice",
	initialState,
	extraReducers: (builder) => {
		builder
			// dashboard list
			.addCase(getDashboardListService.fulfilled, (state, { payload }) => {
				state.dashboardRes.isFetching = false;
				state.dashboardRes.data = payload;
			})
			.addCase(getDashboardListService.pending, (state, { payload }) => {
				state.dashboardRes.isFetching = true;
				state.dashboardRes.error = null;
			})
			.addCase(getDashboardListService.rejected, (state, { payload }) => {
				state.dashboardRes.isFetching = false;
				state.dashboardRes.error = payload;
			})

			// essential list
			.addCase(essentialService.fulfilled, (state, { payload }) => {
				state.essentialResponse.isFetching = false;
				state.essentialResponse.data = payload;
			})
			.addCase(essentialService.pending, (state, { payload }) => {
				state.essentialResponse.isFetching = true;
				state.essentialResponse.error = null;
			})
			.addCase(essentialService.rejected, (state, { payload }) => {
				state.essentialResponse.isFetching = false;
				state.essentialResponse.error = payload;
			})

			// essential company list
			.addCase(essentialServiceByCompany.fulfilled, (state, { payload }) => {
				state.essentialCompanyResponse.isFetching = false;
				state.essentialCompanyResponse.data = payload;
			})
			.addCase(essentialServiceByCompany.pending, (state, { payload }) => {
				state.essentialCompanyResponse.isFetching = true;
				state.essentialCompanyResponse.error = null;
			})
			.addCase(essentialServiceByCompany.rejected, (state, { payload }) => {
				state.essentialCompanyResponse.isFetching = false;
				state.essentialCompanyResponse.error = payload;
			})

			// essential create
			.addCase(essentialCreateService.fulfilled, (state, { payload }) => {
				state.essentialCreateResponse.isFetching = false;
				state.essentialCreateResponse.data = payload;
			})
			.addCase(essentialCreateService.pending, (state, { payload }) => {
				state.essentialCreateResponse.isFetching = true;
				state.essentialCreateResponse.error = null;
			})
			.addCase(essentialCreateService.rejected, (state, { payload }) => {
				state.essentialCreateResponse.isFetching = false;
				state.essentialCreateResponse.error = payload;
			})

			// essential delete
			.addCase(essentialDeleteService.fulfilled, (state, { payload }) => {
				state.essentialDeleteResponse.isFetching = false;
				state.essentialDeleteResponse.data = payload;
			})
			.addCase(essentialDeleteService.pending, (state, { payload }) => {
				state.essentialDeleteResponse.isFetching = true;
				state.essentialDeleteResponse.error = null;
			})
			.addCase(essentialDeleteService.rejected, (state, { payload }) => {
				state.essentialDeleteResponse.isFetching = false;
				state.essentialDeleteResponse.error = payload;
			})
			// addCustomerAddress
			.addCase(addCustomerAddress.fulfilled, (state, { payload }) => {
				state.essentialDeleteResponse.isFetching = false;
				state.essentialDeleteResponse.data = payload;
			})
			.addCase(addCustomerAddress.pending, (state, { payload }) => {
				state.essentialDeleteResponse.isFetching = true;
				state.essentialDeleteResponse.error = null;
			})
			.addCase(addCustomerAddress.rejected, (state, { payload }) => {
				state.essentialDeleteResponse.isFetching = false;
				state.essentialDeleteResponse.error = payload;
			});
	},
});

export const { authDispatch } = essentialSlice.actions;
