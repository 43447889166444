import { createSlice } from "@reduxjs/toolkit";
import {
  purchaseOrderCreateService, purchaseOrderDeleteService, purchaseOrderListService, purchaseOrderStatusUpdateService,
  purchaseOrderUpdateService, purchaseOrderViewService, purchaseOrderReceivedPOViewService
} from "redux/service/purchaseOrderService";

const purchaseOrderCases = [
  {
    api: purchaseOrderCreateService,
    name: "purchaseOrderCreateService",
  },
  {
    api: purchaseOrderUpdateService,
    name: "purchaseOrderUpdateService",
  },
  {
    api: purchaseOrderListService,
    name: "purchaseOrderListService",
  },
  {
    api: purchaseOrderDeleteService,
    name: "purchaseOrderDeleteService",
  },
  {
    api: purchaseOrderViewService,
    name: "purchaseOrderViewService",
  },
  {
    api: purchaseOrderStatusUpdateService,
    name: "purchaseOrderStatusUpdateService"
  },
  {
    api: purchaseOrderReceivedPOViewService,
    name: "purchaseOrderReceivedPOViewService"
  }
];

let initialState = {};
purchaseOrderCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  extraReducers: (builder) => {
    purchaseOrderCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
          state[cases.name].data = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
          state[cases.name].data = null;
        });
    });
    return;
  },
});

export default purchaseOrderSlice.reducer;
