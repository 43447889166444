import { createSlice } from "@reduxjs/toolkit";
import { lumberPriceAddService, lumberPriceDeleteService, lumberPriceListService, lumberPriceUpdateService, lumberPriceViewService } from "redux/service/lumberPriceService";

const lumberPriceCasess = [
	{
		api: lumberPriceListService,
		name: "lumberPriceListService",
	},
	{
		api: lumberPriceViewService,
		name: "lumberPriceViewService",
	},
	{
		api: lumberPriceAddService,
		name: "lumberPriceAddService",
	},
	{
		api: lumberPriceUpdateService,
		name: "lumberPriceUpdateService",
	},
	{
		api: lumberPriceDeleteService,
		name: "lumberPriceDeleteService",
	},
];

let initialState = {};

lumberPriceCasess?.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const lumberPriceSlice = createSlice({
	name: "lumberPrice",
	initialState,
	extraReducers: (builder) => {
		lumberPriceCasess.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
					state[cases.name].data = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
					state[cases.name].data = null;
				});
		});
	},
});

export default lumberPriceSlice.reducer;
