import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";

// supplier service

export const supplierListService = createAsyncThunk("supplierList", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(
			`/supplier?per_page=${bodyParams.per_page}&search=${bodyParams.search}&page=${
				bodyParams.page
			}&from_date=${bodyParams.from_date}&to_date=${bodyParams.to_date}&sort=${
				bodyParams?.sort || "desc"
			}&sort_column=${bodyParams?.sort_column || ""}`
		);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const supplierViewService = createAsyncThunk("supplierView", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/supplier/${bodyParams.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const supplierCreateService = createAsyncThunk("supplierCreate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/supplier`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const supplierUpdateService = createAsyncThunk("supplierUpdate", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.post(`/supplier/${bodyParams?.id}`, bodyParams, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const supplierDeleteService = createAsyncThunk("supplierDelete", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.delete(`/supplier/${bodyParams?.id}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const supplierPOViewService = createAsyncThunk("supplierPOViewService", async (bodyParams, thunkAPI) => {
	try {
		const response = await API.get(`/supplier/purchase-order/${bodyParams?.id}?status=${bodyParams.status}`);
		return response;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});
